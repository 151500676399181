import React from 'react';
import Styles from './styles';
import { useNavigate } from 'react-router-dom';
import { useOrganizationDetails } from '../../../organizations/hooks/useOrganizationDetails';
import useFeatures from '../../../../hooks/useFeatures';
import { useCrmIntegration } from '../../../crm-integration/hooks/use-crm-integration';

export interface Props {
  children?: React.ReactNode;
}

const NoCRMConnectionMessage: React.FC<Props> = ({ children }) => {
  const { isFetching } = useCrmIntegration();

  const navigate = useNavigate();
  const features = useFeatures();
  const availableFeature = features.hubspotIntegration || features.salesforceIntegration;

  if (isFetching) {
    return null;
  }

  return (
    <Styles.Container>
      <Styles.Contents>
        {children || <></>}
        {
          //     <Styles.MessageContainer>
          //       <Styles.Title>No Primary CRM Profile connected yet</Styles.Title>
          //       <Styles.Subtitle>You can still assign a custom domain</Styles.Subtitle>
          //       <Styles.Subtitle>Or</Styles.Subtitle>
          //       <Styles.Subtitle>
          //         Contact your admin to connect a Hubspot or Salesforce account
          //       </Styles.Subtitle>
          //     </Styles.MessageContainer>
          //   )}
          //   <Styles.ActionContainer>
          //     <Styles.Action
          //       onClick={() => {
          //         if (!availableFeature) navigate('/settings?tab=integrations');
          //       }}
          //     >
          //       <Styles.PlusIcon width={'12px'} height={'12px'} />
          //       <Styles.ConnectLabel>Connect CRM</Styles.ConnectLabel>
          //     </Styles.Action>
          //   </Styles.ActionContainer>
        }
      </Styles.Contents>
    </Styles.Container>
  );
};

export { NoCRMConnectionMessage };
