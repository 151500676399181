import { paragon, ConnectUser, SDK_EVENT, IntegrationInstallEvent } from '@useparagon/connect';
import { useCallback, useEffect, useState } from 'react';
import { coreService } from '../../../services/core/core-service';

export const useParagon = (
  uninstallCallback?: () => unknown,
  installCallback?: (provider: string) => unknown,
) => {
  const [userData, setUserData] = useState<ConnectUser>({ authenticated: false });
  const [isAuthenticating, setIsAuthenticating] = useState(true);

  const authenticate = useCallback(async () => {
    setIsAuthenticating(true);
    return coreService
      .getWorkspaceParagonToken()
      .then(data => {
        if (data.paragon) {
          return paragon
            .authenticate(data.paragon.projectId, data.paragon.token)
            .then(async () => {
              const user = paragon.getUser();
              setUserData(user);
            })
            .catch(() => {
              setUserData({ authenticated: false });
            });
        } else {
          setUserData({ authenticated: false });
        }
      })
      .finally(() => {
        setIsAuthenticating(false);
      });
  }, [setIsAuthenticating, setUserData]);

  useEffect(() => {
    authenticate();
  }, []);

  useEffect(() => {
    const uninstallListener = async () => {
      await authenticate();
      if (uninstallCallback) uninstallCallback();
    };

    const installListener = async (installEvent: IntegrationInstallEvent) => {
      await authenticate();
      if (installCallback) installCallback(installEvent.integrationType);
    };

    paragon.subscribe(SDK_EVENT.ON_INTEGRATION_UNINSTALL, uninstallListener);
    paragon.subscribe(SDK_EVENT.ON_INTEGRATION_INSTALL, installListener);

    return () => {
      paragon.unsubscribe(SDK_EVENT.ON_INTEGRATION_UNINSTALL, uninstallListener);
      paragon.unsubscribe(SDK_EVENT.ON_INTEGRATION_INSTALL, installListener);
    };
  }, [authenticate, uninstallCallback, installCallback]);

  return {
    authenticate,
    isFetching: isAuthenticating,
    user: userData,
  };
};
