import { colors } from '../../../../../../../theme/colors';
import styled from 'styled-components';

const Title = styled.div`
  color: var(--Gray-950, #282828);
  font-family: 'Cera Pro';
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 133.333% */
`;

const CRMConnectedStatus = styled.span<{ connected: boolean }>`
  color: ${props => (props.connected ? colors['green'][500] : colors['red'][500])};
`;

const SyncNotice = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  background-color: ${colors['blue'][50]};
  border-radius: 4px;
  border: 1px solid ${colors['blue'][100]};
  color: ${colors['blue'][700]};
  font-family: 'Cera Pro';
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
`;

export default {
  Title,
  CRMConnectedStatus,
  SyncNotice,
};
