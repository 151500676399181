import { v4 } from 'uuid';
import { Question } from './type';

export const MEDDICQuestions = () => {
  return [
    {
      id: v4(),
      position: '1',
      value: 'Metrics',
      context: 'Quantifiable measures of success that your solution can help the customer achieve',
    },
    {
      id: v4(),
      position: '2',
      value: 'Economic Buyer',
      context:
        'positionentifying the key decision-maker who has the financial authority to approve the purchase',
    },
    {
      id: v4(),
      position: '3',
      value: 'Decision Criteria',
      context:
        'Standards or requirements that the customer will use to evaluate and choose among different solutions',
    },
    {
      id: v4(),
      position: '4',
      value: 'Decision Process',
      context: 'Steps and processes that the organization follows to make a purchase decision',
    },
    {
      id: v4(),
      position: '5',
      value: 'Positionentify Pain',
      context:
        "Uncovering the customer's pain points or challenges that your product or service can address",
    },
    {
      id: v4(),
      position: '6',
      value: 'champion',
      context:
        "An internal advocate within the customer's organization who believes in the value of your solution and can influence others",
    },
    {
      id: v4(),
      position: '7',
      value: 'Competition',
      context: "Awareness of competitors that are also vying for the customer's business",
    },
  ] as unknown as Question[];
};

export const BANTQuestions = () => {
  return [
    {
      id: v4(),
      position: '1',
      value: 'Budget',
      context: 'Determining if the prospect has allocated funds for the purchase',
    },
    {
      id: v4(),
      position: '2',
      value: 'Authority',
      context: 'Identifying the person who has the decision-making power to approve the purchase',
    },
    {
      id: v4(),
      position: '3',
      value: 'Need',
      context:
        'Assessing whether the prospect has a genuine requirement for your product or service',
    },
    {
      id: v4(),
      position: '4',
      value: 'Timeline',
      context:
        'Understanding the timeframe in which the prospect intends to make a purchase decision',
    },
  ] as unknown as Question[];
};

export const REACHQuestions = () => {
  return [
    {
      id: v4(),
      position: '1',
      value: 'Research',
      context: 'Gathering detailed information about the prospect and their industry',
    },
    {
      id: v4(),
      position: '2',
      value: 'Engage',
      context: 'Initiating meaningful conversations to build relationships with the prospect',
    },
    {
      id: v4(),
      position: '3',
      value: 'Assess',
      context: "Evaluating the prospect's needs, challenges, and potential fit for your solution",
    },
    {
      id: v4(),
      position: '4',
      value: 'Collaborate',
      context: 'Working together with the prospect to develop tailored solutions',
    },
    {
      id: v4(),
      position: '5',
      value: 'Help',
      context: 'Providing support and demonstrating how your solution addresses their needs',
    },
  ] as unknown as Question[];
};

export const ConflictResolutionQuestions = () => {
  return [
    {
      id: v4(),
      position: '1',
      value: 'Identify the Conflict',
      context: 'Recognizing and acknowledging the existence of a conflict between parties',
    },
    {
      id: v4(),
      position: '2',
      value: 'Understand Perspectives',
      context: 'Listening to all parties to comprehend their viewpoints and concerns',
    },
    {
      id: v4(),
      position: '3',
      value: 'Communicate Openly',
      context: 'Encouraging honest and respectful dialogue to share feelings and opinions',
    },
    {
      id: v4(),
      position: '4',
      value: 'Find Common Ground',
      context: 'Identifying shared goals or interests to build a basis for agreement',
    },
    {
      id: v4(),
      position: '5',
      value: 'Explore Solutions',
      context: 'Collaboratively brainstorming possible ways to resolve the conflict',
    },
    {
      id: v4(),
      position: '6',
      value: 'Agree on Action',
      context: 'Selecting the most acceptable solution and outlining steps to implement it',
    },
    {
      id: v4(),
      position: '7',
      value: 'Follow Up',
      context: 'Monitoring the situation to ensure the conflict has been effectively resolved',
    },
  ] as unknown as Question[];
};
export const CustomerSuccessFoundationsQuestions = () => {
  return [
    {
      id: v4(),
      position: '1',
      value: 'Tech Stack Changes',
      context:
        'Moves, Migrations, Adjustments, or Eliminations to their  tech stack may signal shifting priorities. Budget shifts, reduced complexity, greater visibility and value realization are all potential reasons for the change',
    },
    {
      id: v4(),
      position: '2',
      value: 'Product Challenges',
      context:
        'Challenges, blockers, bugs or issues that prevent adoption, utilization and successful outcomes.',
    },
    {
      id: v4(),
      position: '3',
      value: 'Goals',
      context:
        'Short, mid and long term goals, KPIs and focus. What are they working on and why is that important to the business?',
    },
    {
      id: v4(),
      position: '4',
      value: 'Enhancement Ideas and/or Feature Requests',
      context:
        'What would this do for the customer? How would an additional feature or change to the current product create an enhanced experience, drive more value and fuel success?',
    },
    {
      id: v4(),
      position: '5',
      value: 'Issues',
      context:
        "What are their biggest issues, frustrations and challenges? Both within and beyond the product. What's getting in the way of success?",
    },
    {
      id: v4(),
      position: '6',
      value: ' Success Stories',
      context:
        'Ways they have seen success- outcomes achieved, value delivered, value realized and other wins',
    },
    {
      id: v4(),
      position: '7',
      value: 'Org changes',
      context:
        'Promotions, demotions, RIFs, layoffs, eliminations, new leaders, organizational alignment changes can all potentially change the business function and relationship.',
    },
  ] as unknown as Question[];
};
