import { Button, Radio, Space, Spin } from 'antd';
import { Card } from '../../../../components/Card';
import { useCustomerIntelligenceFilters } from '../../../../features/insights/hooks/useCustomerIntelligenceFilters';
import { BarChartOutlined, FundViewOutlined, TagsOutlined } from '@ant-design/icons';
import { useCustomerIntelligenceData } from '../../../../features/insights/hooks/useCustomerIntelligenceData';
import { TimelineChart } from '../TimelineChart';
import { useMemo, useRef, useState } from 'react';
import { CumulativeChart } from '../CumulativeChart';
import { Summary } from '../Summary';
import { ExportDropdown } from '../ExportDropdown';
import { coreService } from '../../../../services/core/core-service';
import { storeToInsightArgs } from '../../../../features/insights/context/CustomerIntelligenceProvider/utils';
import { json2csv } from 'json-2-csv';
import { timelineToObj } from '../../utils';
import { useInsightsAnalytics } from '../../../../features/insights/hooks/useInsightsAnalytics';

import Styles from './styles';
import { useSession } from '../../../../hooks/useSession';
import { OptionalTooltipWrapper } from '../../../../componentsV2/OptionalTooltipWrapper';
import useFeatures from '../../../../hooks/useFeatures';
import { SubscribeToTeamsPlanLink } from '../../../../componentsV2/UpgradeToPro';
const { Filters, Contents, ChartContainer, SpinnerContainer } = Styles;

export const CategoriesCard: React.FC<{ onManageCategories: () => unknown }> = props => {
  const [currentView, setCurrentView] = useState<string>('timeline');
  const {
    category,
    contractValueMax,
    contractValueMin,
    contractRenewalDateBefore,
    members,
    lifecycleStages,
    accounts,
    dateRange,
    industries,
    meetingsImInvitedOnly,
    priorities,
  } = useCustomerIntelligenceFilters();
  const { groups, isFetching } = useCustomerIntelligenceData();
  const { summary } = useInsightsAnalytics();

  const features = useFeatures();
  const availableFeature = features.customInsightsCategories;

  const { user } = useSession();
  const isManageEnabled = user?.membership?.role !== 'MEMBER' && availableFeature;

  const clearDependencies = useMemo(() => {
    return [
      category,
      contractValueMax,
      contractValueMin,
      contractRenewalDateBefore,
      members,
      lifecycleStages,
      accounts,
      dateRange,
      industries,
      meetingsImInvitedOnly,
      priorities,
    ];
  }, [
    category,
    contractValueMax,
    contractValueMin,
    contractRenewalDateBefore,
    members,
    lifecycleStages,
    accounts,
    dateRange,
    industries,
    meetingsImInvitedOnly,
    priorities,
  ]);

  const chartRef = useRef<HTMLDivElement | null>(null);

  const { timelineSeries, cumulativeSeries } = useMemo(() => {
    const timelineSeries = groups.map(gr => {
      return {
        name: gr.name,
        data: gr.daily.map(daily => ({ x: daily.date, y: daily.count })),
      };
    });

    const cumulativeSeries = groups.map(gr => {
      return {
        name: gr.name,
        total: gr.total,
      };
    });
    return { timelineSeries, cumulativeSeries };
  }, [groups]);

  const renderedChart = useMemo(() => {
    if (currentView === 'timeline') {
      return <TimelineChart data={{ series: timelineSeries }} />;
    }

    return <CumulativeChart data={{ series: cumulativeSeries }} />;
  }, [timelineSeries, cumulativeSeries, currentView]);

  const handleExportCsv = () => {
    if (currentView === 'timeline') {
      return json2csv(timelineToObj(timelineSeries));
    }
    return json2csv(cumulativeSeries);
  };

  return (
    <Card title={`${category} Trackers`}>
      <Contents>
        <Filters>
          <Radio.Group value={currentView} onChange={e => setCurrentView(e.target.value)}>
            <Radio.Button value={'timeline'}>
              <Space>
                <FundViewOutlined />
                <span>Timeline View</span>
              </Space>
            </Radio.Button>
            <Radio.Button value={'cumulative'}>
              <Space>
                <BarChartOutlined />
                <span>Cumulative View</span>
              </Space>
            </Radio.Button>
          </Radio.Group>
          <ExportDropdown formatCsvFn={handleExportCsv} chartRef={chartRef.current} />
          <OptionalTooltipWrapper
            display={!isManageEnabled}
            value={
              !availableFeature ? (
                <SubscribeToTeamsPlanLink title="Upgrade to a Business plan or higher to create custom insight categories." />
              ) : (
                'Only Owners or Admins can manage custom categories'
              )
            }
          >
            <Button
              disabled={!isManageEnabled}
              icon={<TagsOutlined />}
              onClick={props.onManageCategories}
            >
              Manage trackers
            </Button>
          </OptionalTooltipWrapper>
        </Filters>
        <ChartContainer ref={chartRef}>
          {isFetching ? (
            <SpinnerContainer>
              <Spin />
            </SpinnerContainer>
          ) : (
            renderedChart
          )}
        </ChartContainer>
        <Summary
          clearDependencies={clearDependencies}
          trackGenerateClick={() => summary.regenerated({ type: category })}
          generateFn={async () => {
            const insightArgs = storeToInsightArgs();
            if (insightArgs) {
              delete insightArgs.group;
              return coreService.summarizeItems(insightArgs).then(response => {
                return response.summary;
              });
            }
            return '';
          }}
          watchItemsFn={async () => {
            summary.playlist.viewed({ type: category });
            const insightArgs = storeToInsightArgs();
            if (insightArgs) {
              delete insightArgs.group;
              return coreService.getInsightsItems(insightArgs).then(response => {
                return response.items;
              });
            }
            return [];
          }}
        />
      </Contents>
    </Card>
  );
};
