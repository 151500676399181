import styled from 'styled-components';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
`;

const Description = styled.div`
  align-self: stretch;
  color: var(--Gray-700, #6a6a6a);
  font-family: 'Cera Pro';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
`;
const Description2 = styled.div`
  align-self: stretch;
  color: var(--Gray-700, #6a6a6a);
  font-family: 'Cera Pro';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
`;

export default {
  Content,
  Description,
  Description2
};
