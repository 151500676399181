import { Button, Divider, Flex, Input, Spin } from 'antd';
import React, { useMemo, useState } from 'react';
import up from '../../../../../assets/svg/upload-04.svg';
import { SearchOutlined } from '@ant-design/icons';
import { Results } from './Results/Results';
import { ResultItem } from './Results/ResultItem/ResultItem';
import { useNavigate } from 'react-router-dom';
import { useMeetingAccountSearch } from '../../hooks/useMeetingAccountSearch';
import { ProspectAccount } from '../../types';
import { NoCRMConnectionMessage } from '../../../../shared/components/CRMConnection';
import { useAccountSelectionModal } from '../../hooks/useAccountSelectionModal';
import { Modal } from '../../../../../componentsV2/Modal';
import { coreAPI } from '../../../../../API/core';
import { Plus } from '../../../../shared/components/Icons/Plus';
import Styled from './styles';
import { useToast } from '../../../../../hooks/useToast';
import { useCrmIntegration } from '../../../../crm-integration/hooks/use-crm-integration';

const {
  Container,
  Circle,
  Content,
  SubContent,
  Header,
  Title,
  MatchingCRMAccounts,
  MatchingCRMAccountsContainer,
  MatchingCRMAccountsTitle,
  ProspectedAccountsContainer,
  AccountsContainer,
  DetailText,
  OtherCrmAccounts,
  AssignDomainText,
  CreateDomainContainer,
  CreateDomainText,
} = Styled;

interface Props {
  onAccountChanged: (
    eventId: string,
    account: { id: string; name: string; provider: string; mappingId: string },
  ) => unknown;
}

export const AccountModal: React.FC<Props> = ({ onAccountChanged }) => {
  const navigate = useNavigate();
  const [selectedAccount, setSelectedAccount] = useState<ProspectAccount | null>(null);
  const { show, close, prospectAccounts, eventId } = useAccountSelectionModal();
  const {
    searchTerm,
    isSearching,
    accounts,
    changeSearchTerm,
    searchAccountOrEmailDomain,
    searched,
    restore,
  } = useMeetingAccountSearch(false);
  const { workspaceCrmIntegration, isFetching } = useCrmIntegration();
  const [isWorking, setIsWorking] = useState(false);

  const { error, success } = useToast();

  const uniqueAccounts = useMemo(() => {
    return accounts.filter(
      searchResult => !prospectAccounts.find(p => p.providerId === searchResult.providerId),
    );
  }, [accounts]);

  const reset = () => {
    setSelectedAccount(null);
    close();
    restore();
  };

  const goToIntegrations = () => {
    navigate('/settings?tab=integrations');
    reset();
  };

  const changeAccount = (account: ProspectAccount) => {
    const api = new coreAPI();
    setIsWorking(true);
    api
      .setRecapAccount(eventId, account.providerId, account.provider, account.name)
      .then(response => {
        onAccountChanged(eventId, { ...response.account, mappingId: account.providerId });
        reset();
        success('Account updated successfully');
      })
      .catch(() => {
        error('Failed to update account');
      })
      .finally(() => {
        setIsWorking(false);
      });
  };

  const handleApplyChanges = () => {
    if (!selectedAccount) {
      return;
    }
    changeAccount(selectedAccount);
  };

  const handleCreateAndAssign = (id: string) => {
    changeAccount({
      providerId: id,
      provider: 'email',
      name: id,
    });
  };

  const handleCancel = () => {
    reset();
  };

  return (
    <Modal
      onClose={handleCancel}
      open={show}
      width={600}
      title="Link to CRM"
      secondaryAction={{
        label: 'Cancel',
        onClick: handleCancel,
        disabled: isWorking,
      }}
      primaryAction={{
        label: 'Apply changes',
        loading: isWorking,
        disabled: isWorking || !selectedAccount,
        onClick: handleApplyChanges,
      }}
    >
      <Container>
        <Header>
          <Circle>
            <img src={up} style={{ height: '24px', width: '24px' }} />
          </Circle>
          <Content>
            <Title>Select an account</Title>
            <SubContent>Recaps will be pushed into the account you choose.</SubContent>
          </Content>
        </Header>
        <Flex gap={8}>
          <Input
            allowClear
            disabled={isSearching}
            onChange={ev => changeSearchTerm(ev.target.value)}
            placeholder={'Type account name'}
            value={searchTerm}
            // ref={input => {
            //   if (input) setTimeout(() => input.focus());
            // }}
          />
          <Button
            disabled={isSearching || searchTerm.length <= 3}
            onClick={searchAccountOrEmailDomain}
            type="primary"
          >
            Search
          </Button>
        </Flex>

        <AccountsContainer>
          {(prospectAccounts.length || 0) > 0 && (
            <>
              <MatchingCRMAccountsContainer>
                <MatchingCRMAccounts>
                  <MatchingCRMAccountsTitle>{`Matching accounts (${
                    prospectAccounts.length || 0
                  })`}</MatchingCRMAccountsTitle>
                </MatchingCRMAccounts>
              </MatchingCRMAccountsContainer>
              <ProspectedAccountsContainer>
                <Divider style={{ margin: '0px' }} />
                {prospectAccounts?.map(acc => (
                  <ResultItem
                    key={acc.providerId}
                    onClick={() => {
                      setSelectedAccount(acc);
                    }}
                    name={acc.name}
                    provider={acc.provider}
                    selected={acc.providerId === selectedAccount?.providerId}
                  />
                ))}
              </ProspectedAccountsContainer>
            </>
          )}

          {isSearching && (
            <Spin style={{ marginTop: '48px' }} tip="Searching accounts...">
              <span></span>
            </Spin>
          )}
          {searched && !isSearching ? (
            <OtherCrmAccounts>
              <MatchingCRMAccountsTitle>{`Accounts (${uniqueAccounts.length})`}</MatchingCRMAccountsTitle>
              <Results
                loading={isSearching}
                accounts={uniqueAccounts}
                onSelect={prospect => setSelectedAccount(prospect)}
                selected={selectedAccount}
                setSelected={provider => setSelectedAccount(provider)}
              />
            </OtherCrmAccounts>
          ) : (
            <NoCRMConnectionMessage />
          )}

          {/*searchTerm &&
            !isSearching &&
            accounts.length === 0 &&
            !workspaceCrmIntegration?.workspace.integration &&
            !isFetching && (
              <CreateDomainContainer>
                <CreateDomainText>{`The account “${searchTerm}” doesn’t exist...`}</CreateDomainText>
                <AssignDomainText onClick={() => handleCreateAndAssign(searchTerm)}>
                  <Plus /> Create and assign this account
                </AssignDomainText>
              </CreateDomainContainer>
            )*/}
        </AccountsContainer>
      </Container>
    </Modal>
  );
};
