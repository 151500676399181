import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Credentials, Settings, User, UserOrganization } from '../typings/session';
import { Session } from '../typings/store';

export const sortOrganizations = (a: UserOrganization, b: UserOrganization) => {
  if (a.isPrimary && !b.isPrimary) {
    return -1;
  }
  if (!a.isPrimary && b.isPrimary) {
    return 1;
  }
  if (a.isPrivate && !b.isPrivate) {
    return -1;
  }
  if (!a.isPrivate && b.isPrivate) {
    return 1;
  }
  return 0;
};

const getInitialSidebarOption = () => {
  try {
    const saved = localStorage.getItem('selectedSideBarOption');
    return saved || '1'; 
  } catch {
    return '1';
  }
};

export const initialState: Session = {
  status: {
    isLogin: false,
    isUpdating: false,
    isDone: false,
    error: undefined,
  },
  user: undefined,
  zoom: {
    isSyncing: false,
    error: undefined,
  },
  calendar: {
    isSyncing: false,
    error: undefined,
  },
  slack: {
    isSyncing: false,
    error: undefined,
  },
  salesforce: {
    isSyncing: false,
    error: undefined,
  },
  hubspot: {
    isSyncing: false,
    error: undefined,
  },
  sidebarCollapsed: false,
  selectedSideBarOption: getInitialSidebarOption(),
};


const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    // Login/Logout.
    login(state, action: PayloadAction<Credentials>) {
      state.status.isLogin = !!action;
      state.status.error = undefined;
    },
    loginSuccess(state, action: PayloadAction<{ user: User }>) {
      state.status.isLogin = false;
      state.user = action.payload.user;

      // order organizations
      state.user.organizations.sort(sortOrganizations);
    },
    loginError(state, action: PayloadAction<{ error?: string }>) {
      state.status.isLogin = false;
      state.status.error = action.payload.error;
    },
    logoutUser(state) {
      state.status = initialState.status;
      state.user = undefined;
    },
    // Password.
    recoverPassword(state, action: PayloadAction<{ email: string; from: string }>) {
      state.status.isUpdating = !!action;
      state.status.error = undefined;
    },
    recoverPasswordSuccess(state) {
      state.status.isUpdating = false;
    },
    resetPassword(state, action: PayloadAction<{ token: string; password: string }>) {
      state.status.isUpdating = !!action;
      state.status.error = undefined;
      state.status.isDone = false;
    },
    resetPasswordSuccess(state) {
      state.status.isUpdating = false;
      state.status.isDone = true;
    },
    resetPasswordError(state, action: PayloadAction<{ error?: string }>) {
      state.status.isUpdating = false;
      state.status.isDone = false;
      state.status.error = action.payload.error;
    },
    // User.
    updateUser(state, action: PayloadAction<Partial<User>>) {
      state.status.isUpdating = !!action;
      state.status.error = undefined;
    },
    updateUserSuccess(state, action: PayloadAction<Partial<User>>) {
      state.status.isUpdating = false;
      state.user = {
        ...state.user,
        ...action.payload,
      } as User;
    },
    fetchUser(state) {
      state.status.isUpdating = true;
      state.status.error = undefined;
    },
    silentFetchUser(state) {
      state.status.error = undefined;
    },
    fetchUserSuccess(state, action: PayloadAction<Partial<User>>) {
      state.status.isUpdating = false;
      state.status.error = undefined;

      state.user = {
        ...state.user,
        ...action.payload,
      } as User;

      // order organizations
      state.user.organizations.sort(sortOrganizations);
    },
    updateSettings(state, action: PayloadAction<Partial<Settings>>) {
      state.user!.configuration.settings = {
        ...state.user!.configuration.settings,
        ...action.payload,
      } as Settings;
    },
    // Zoom.
    integrateZoom(state) {
      state.zoom.error = undefined;
      state.zoom.isSyncing = true;
    },
    removeZoom(state) {
      state.zoom.error = undefined;
      state.zoom.isSyncing = true;
    },
    integrateZoomResponse(state, action: PayloadAction<{ error?: string }>) {
      state.zoom.error = action.payload.error;
      state.zoom.isSyncing = false;
    },
    // Calendar.
    integrateCalendar(state, action: PayloadAction<{ provider: string }>) {
      state.calendar.error = undefined;
      state.calendar.provider = action.payload.provider;
      state.calendar.isSyncing = true;
    },
    removeCalendar(state) {
      state.calendar.error = undefined;
      state.calendar.provider = undefined;
      state.calendar.isSyncing = true;
    },
    integrateCalendarResponse(state, action: PayloadAction<{ error?: string }>) {
      state.calendar.error = action.payload.error;
      state.calendar.isSyncing = false;
      if (action.payload) {
        state.calendar.provider = undefined;
      }
    },
    // Slack.
    integrateSlack(state) {
      state.slack.error = undefined;
      state.slack.isSyncing = true;
    },
    removeSlack(state) {
      state.slack.error = undefined;
      state.slack.isSyncing = true;
    },
    integrateSlackResponse(state, action: PayloadAction<{ error?: string }>) {
      state.slack.error = action.payload.error;
      state.slack.isSyncing = false;
    },
    // Salesforce.
    integrateSalesforce(state) {
      state.salesforce.error = undefined;
      state.salesforce.isSyncing = true;
    },
    removeSalesforce(state) {
      state.salesforce.error = undefined;
      state.salesforce.isSyncing = true;
      localStorage.removeItem(`recapsFilter.account`);
      localStorage.removeItem(`eventsFilter.account`);
    },
    integrateSalesforceResponse(state, action: PayloadAction<{ error?: string }>) {
      state.salesforce.error = action.payload.error;
      state.salesforce.isSyncing = false;
    },
    // Hubspot.
    integrateHubspot(state) {
      state.hubspot.error = undefined;
      state.hubspot.isSyncing = true;
    },
    removeHubspot(state) {
      state.hubspot.error = undefined;
      state.hubspot.isSyncing = true;
      localStorage.removeItem(`recapsFilter.account`);
      localStorage.removeItem(`eventsFilter.account`);
    },
    integrateHubspotResponse(state, action: PayloadAction<{ error?: string }>) {
      state.hubspot.error = action.payload.error;
      state.hubspot.isSyncing = false;
    },
    // Error.
    error(state, action: PayloadAction<{ error?: string }>) {
      state.status.isUpdating = false;
      state.status.error = action.payload.error;
    },
    updateFirstTimeUser(state) {
      state.status.isUpdating = true;
    },
    updateFirstTimeUserSuccess(state) {
      if (state.user) {
        state.user.firstTimeUser = false;
      }
      state.status.isUpdating = false;
    },
    toggleSidebar(state) {
      state.sidebarCollapsed = !state.sidebarCollapsed;
    },
    selectSidebarOption(state, action: PayloadAction<{ selectedSideBarOption: string }>) {
      state.selectedSideBarOption = action.payload.selectedSideBarOption;
      try {
        localStorage.setItem('selectedSideBarOption', action.payload.selectedSideBarOption);
      } catch (error) {
        console.error('Error saving sidebar selection:', error);
      }
    },
  },
});

export const { silentFetchUser, fetchUser, fetchUserSuccess } = sessionSlice.actions;
export const { updateUser, updateUserSuccess, updateFirstTimeUser, updateFirstTimeUserSuccess } =
  sessionSlice.actions;
export const { updateSettings } = sessionSlice.actions;
export const { login, loginSuccess, loginError, logoutUser } = sessionSlice.actions;
export const { resetPassword, resetPasswordError, resetPasswordSuccess } = sessionSlice.actions;
export const { recoverPassword, recoverPasswordSuccess } = sessionSlice.actions;
export const { integrateZoom, removeZoom, integrateZoomResponse } = sessionSlice.actions;
export const { integrateCalendar, removeCalendar, integrateCalendarResponse } =
  sessionSlice.actions;
export const { integrateSlack, removeSlack, integrateSlackResponse } = sessionSlice.actions;
export const { integrateSalesforce, removeSalesforce, integrateSalesforceResponse } =
  sessionSlice.actions;
export const { integrateHubspot, removeHubspot, integrateHubspotResponse } = sessionSlice.actions;
export const { error } = sessionSlice.actions;
export const { toggleSidebar, selectSidebarOption } = sessionSlice.actions;

export const sessionReducers = sessionSlice.reducer;
