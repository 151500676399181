import { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { SearchAccountItem, SearchResult } from '../types';
import { coreAPI } from '../../../../API/core';

export const useMeetingAccountSearch = (debounce = true) => {
  const [search, setSearch] = useState<string>('');
  const [debouncedSearch] = useDebounce(search, 300);
  const [searchResult, setSearchResult] = useState<SearchResult>({
    error: null,
    searched: false,
    items: [],
  });
  const [isSearching, setIsSearching] = useState(false);

  const searchAccountOrEmailDomain = () => {
    setIsSearching(true);
    setSearchResult({ error: null, searched: false, items: [] });

    const api = new coreAPI();
    api
      .fetchUserAccounts(search)
      .then(data => {
        const result: SearchAccountItem[] = data.accounts.map(account => ({
          providerId: account.id,
          name: account.name,
          provider: account.provider,
        }));
        setSearchResult({ error: null, searched: true, items: result });
      })
      .catch(error => {
        setSearchResult({ error: 'Error searching accounts', searched: false, items: [] });
      })
      .finally(() => setIsSearching(false));
  };

  useEffect(() => {
    if (search !== '' && search.length >= 3 && debounce) searchAccountOrEmailDomain();
  }, [debouncedSearch]);

  const restore = () => {
    setSearchResult({ error: null, searched: false, items: [] });
    setSearch('');
  };

  const changeSearchTerm = (value: string) => {
    if (value === '') {
      restore();
    } else {
      setSearch(value);
    }
  };

  return {
    restore,
    accounts: searchResult.items,
    searched: searchResult.searched,
    isSearching,
    searchTerm: search,
    searchAccountOrEmailDomain,
    changeSearchTerm: changeSearchTerm,
  };
};
