import styled from 'styled-components';

const Container = styled.div`
  width: 330px;
  min-height: 220px;
  border-radius: 6px;
  border: 1px solid var(--gray-300, #d1d1d1);
  background: #fff;
  box-shadow: 0px 20px 60px 0px rgba(0, 0, 0, 0.2);
   @media (max-width: 1500px) {
    height: 400px; 
    overflow: auto;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  padding: 16px 24px 24px 16px;
  gap: 8px;
`;

const ClearAll = styled.span<{ disabled: boolean }>`
  color: ${props => (props.disabled ? 'var(--gray-200, #e4e4e4)' : 'var(--primary-500, #FF6C54)')};

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  :after {
    content: 'Clear all';
  }
`;

const Title = styled.span`
  color: var(--gray-950, #282828);
  font-family: Cera Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
  flex: 1;
`;

const Contents = styled.div``;

export default {
  Container,
  Header,
  ClearAll,
  Title,
  Contents,
};
