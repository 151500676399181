import { Button } from 'antd';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 4px 0px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  gap: 20px;
  margin-bottom: 24px;
`;

const Title = styled.div``;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  color: var(--new-color-shades-gray-950, #282828);
  font-family: Cera Pro;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 155.556% */
  letter-spacing: 0.09px;
`;

const AccountsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 400px;
  padding: 20px 0px;
  overflow-y: auto;
`;

const MatchingCRMAccountsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const MatchingCRMAccounts = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const MatchingCRMAccountsTitle = styled.div`
  color: var(--gray-950, #282828);
  font-family: Cera Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  padding-top: 8px;
`;

const RefreshButtonContainer = styled.div`
  display: flex;
`;

const SubContent = styled.div`
  color: var(--new-color-shades-gray-600, #818181);
  border-color: none;
  box-shadow: none;
  /* Text sm/Regular */

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
`;

const DetailText = styled.div`
  color: var(--gray-500, #a6a6a6);
  font-variant-numeric: stacked-fractions;

  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-top: 8px;
`;

const Circle = styled.div`
  display: flex;
  width: 48px;
  height: 48px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: var(--new-color-shades-primary-100, #fff0ee);
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 12px;
`;

const RefreshButton = styled(Button)`
  color: var(--primary-500, #ff6c54);

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  border: none;
  box-shadow: none;
`;

const ProspectedAccountsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const OtherCrmAccounts = styled.div`
  display: flex;
  flex-direction: column;
`;

const DomainAccounts = styled.div`
  display: flex;
  flex-direction: column;
`;

const CreateDomainContainer = styled.div`
  display: flex;
  padding: 24px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
`;

const CreateDomainText = styled.div`
  color: var(--gray-700, #6a6a6a);
  font-family: Cera Pro;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 117.647% */
`;

const AssignDomainText = styled.div`
  color: var(--primary-500, #ff6c54);

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  cursor: pointer;
`;

export default {
  Container,
  Header,
  Title,
  Circle,
  Content,
  SubContent,
  DetailText,
  ButtonGroup,
  MatchingCRMAccounts,
  MatchingCRMAccountsContainer,
  MatchingCRMAccountsTitle,
  RefreshButtonContainer,
  RefreshButton,
  ProspectedAccountsContainer,
  AccountsContainer,
  OtherCrmAccounts,
  DomainAccounts,
  CreateDomainContainer,
  CreateDomainText,
  AssignDomainText,
};
