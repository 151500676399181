import styled from 'styled-components';
import { colors } from '../../../../../theme/colors';

const Container = styled.div<{ canClick: boolean }>`
overflow:auto;
  display: grid;
  grid-template-columns: 80px auto auto;
  gap: 10px;
  width: 100%;
  background-color: ${colors.white};
  padding: 12px;
  margin-bottom: 10px;
  border-radius: var(--6px, 6px);
  border: 1px solid #ecebec;
  border-radius: 6px;

  :hover {
    border: ${({ canClick }) =>
      canClick ? `1px solid ${colors.primary[200]}` : ' 1px solid #ecebec'};
    outline: ${({ canClick }) => (canClick ? '2px solid #ecebec' : 'none')};
  }
`;

export default { Container };
