import { Tooltip } from 'antd';
import { Table, TableColumnDefinition } from '../../../../../../componentsV2/Table';
import { TopicLabel } from '../../../TopicLabel';

export type TopAccountsItemType = {
  id: string;
  name: string;
  mentions: number;
};

export type TopTopicRecord = {
  id: string;
  name: string;
  mentions: number | null;
  meetings: number | null;
  accounts: number | null;
  description?: string;
};

interface Props {
  data: TopTopicRecord[];
  loading?: boolean;
  showAccountColumn?: boolean;
  onTopicClicked: (args: { id: string; name: string }) => void;
  insightType?: string;
}

export const TopTopicsTable: React.FC<Props> = ({
  data,
  loading = false,
  onTopicClicked,
  showAccountColumn = true,
  insightType,
}) => {
  const columns: TableColumnDefinition<TopTopicRecord>[] = [
    {
      key: 'topic',
      title: 'Topics',
      render: value => {
        return (
          <TopicLabel
            name={`${value.name}`}
            onClick={() => onTopicClicked({ id: value.id, name: value.name })}
          />
        );
      },
    },
  ];

  if (insightType === undefined) {
    columns.push({
      key: 'description',
      title: 'Description',
      render: value => {
        if (!value.description) return null;

        const truncatedDescription =
          value.description.length > 100
            ? `${value.description.substring(0, 100)}...`
            : value.description;

        return (
          <Tooltip title={value.description}>
            <div className="max-w-md truncate">{truncatedDescription}</div>
          </Tooltip>
        );
      },
    });
  }

  columns.push(
    {
      key: 'mentions',
      title: 'Mentions',
      dataIndex: 'mentions',
    },
    {
      key: 'meetings',
      title: 'Meetings',
      dataIndex: 'meetings',
    },
  );

  if (showAccountColumn) {
    columns.splice(1, 0, {
      key: 'accounts',
      title: '# of Accounts',
      dataIndex: 'accounts',
    });
  }

  return (
    <Table
      locale={{
        emptyText: loading ? `Loading top topics...` : 'No topics found',
      }}
      pagination={false}
      loading={loading}
      columns={columns}
      data={data}
    />
  );
};
