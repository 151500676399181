import { useState } from 'react';
import { SelectedTopic } from '../TopAccounts/types';
import { useQuery } from '@tanstack/react-query';
import { coreService } from '../../../../services/core/core-service';
import { Button, Flex, Select, Space } from 'antd';
import { ViewAllTopicsModal } from './components/ViewAllTopicsModal';
import { TopTopicsTable } from './components/TopTopicsTable';
import Card from '../../../../componentsV2/Card';

interface TopTopicsProps {
  onTopicSelected: (args: { id: string; name: string }) => unknown;
  insightType?: string;
  filters: {
    from: string;
    to: string;
    accountId?: string;
    crmFilters?: Record<string, string[]>;
  };
  showAccountColumn?: boolean;
}

export const TopTopics: React.FC<TopTopicsProps> = ({
  onTopicSelected,
  filters,
  insightType,
  showAccountColumn,
}) => {
  const [sortBy, setSortBy] = useState<string>('mentions');
  const [showAllTopics, setShowAllTopics] = useState(false);

  const { data, isFetching } = useQuery({
    queryKey: [
      'top-topics',
      filters.from,
      filters.to,
      filters.accountId,
      filters.crmFilters,
      sortBy,
      insightType,
    ],
    queryFn: async args => {
      const options: any = {};
      if (filters.accountId) {
        options['accountIds'] = [filters.accountId];
      }

      if (insightType) {
        options['insightType'] = insightType;
      }

      if (filters.crmFilters) {
        options['crmFilters'] = filters.crmFilters;
      }

      const data = await coreService.getTopTopics(filters.from, filters.to, 1, 10, sortBy, options);

      if (insightType === 'Risks') {
        data.topics = data.topics.map(topic => ({
          ...topic,
          description: `This is a sample risk description for ${topic.name}. It contains details about potential impacts, mitigation strategies, and other relevant information that might be quite long and need truncation. The risk level might be high, medium, or low depending on various factors including market conditions, regulatory environment, and business context.`,
        }));
      }

      return data;
    },
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const totalTopics = data?.pagination.items.total || 0;

  return (
    <Flex vertical gap={'16px'}>
      <ViewAllTopicsModal
        open={showAllTopics}
        onClose={() => setShowAllTopics(false)}
        onTopicClicked={topic => {
          setShowAllTopics(false);
          onTopicSelected(topic);
        }}
        insightType={insightType}
        filters={filters}
        sortBy={sortBy}
      />
      <Card
        title={`Top ${insightType ? `${insightType} ` : ''}Topics`}
        containerStyle={{ flex: 1 }}
        learnMoreLink="https://google.com"
      >
        <Flex vertical gap={'16px'}>
          <Space>
            <Button type="primary" onClick={() => setShowAllTopics(true)}>
              View All ({totalTopics})
            </Button>
            <Select
              value={sortBy}
              options={[
                {
                  value: 'mentions',
                  label: '# of Mentions',
                },
                {
                  value: 'meetings',
                  label: '# of Meetings',
                },
                {
                  value: 'accounts',
                  label: '# of Accounts',
                },
              ]}
              onChange={value => setSortBy(value)}
            />
          </Space>
          <TopTopicsTable
            showAccountColumn={showAccountColumn}
            loading={isFetching}
            data={data?.topics || []}
            onTopicClicked={onTopicSelected}
            insightType={insightType}
          />
        </Flex>
      </Card>
    </Flex>
  );
};
