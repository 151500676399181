import { useCallback } from 'react';
import { useParagon } from '../../paragon/hooks/use-paragon';
import { paragon } from '@useparagon/connect';
import { useQuery } from '@tanstack/react-query';
import { coreService } from '../../../services/core/core-service';
import { useSession } from '../../../hooks/useSession';

export const useCrmIntegration = () => {
  const { user: updateUser } = useSession();

  const {
    data,
    isFetching: isFetchingWorkspaceCrmIntegration,
    refetch,
  } = useQuery({
    queryKey: ['workspace-crm-integration', updateUser?.id],
    queryFn: () => {
      return coreService.getWorkspaceCrmIntegration();
    },
    refetchOnMount: true,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  const handleUninstall = useCallback(async () => {
    await coreService.removeCrmIntegration();
    refetch();
  }, [refetch]);

  const handleInstall = useCallback(
    async (provider: string) => {
      await coreService.createWorkspaceCrmIntegration(provider);
      refetch();
    },
    [refetch],
  );

  const { isFetching, authenticate } = useParagon(handleUninstall, handleInstall);

  const isConnectedEnabled = data?.workspace.integration === null;
  const isDisconnectEnabled = data?.workspace.integration !== null;

  const disconnect = async () => {
    if (data?.workspace.integration) {
      await authenticate();
      paragon.connect(data?.workspace.integration.provider, {});
    }
  };

  const connect = async (provider: string) => {
    await authenticate();
    paragon.connect(provider, {});
  };

  return {
    isFetching: isFetchingWorkspaceCrmIntegration,
    isConnectedEnabled,
    isDisconnectEnabled,
    disconnect,
    connect,
    workspaceCrmIntegration: data,
    refetchCrmIntegration: refetch,
  };
};
