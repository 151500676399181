import { CheckCircleFilled, ExclamationCircleFilled, QuestionCircleOutlined, SettingFilled } from '@ant-design/icons';
import { Space, Tooltip } from 'antd';
import { EventStatus } from '../../../../../../types/commonTypes';
import React from 'react';
import Styles from './styles';

const { Container } = Styles;

interface StatusConfiguration {
  color: string;
  icon: any;
  backgroundColor: string;
}

const defaultStatus: StatusConfiguration = {
  icon: ExclamationCircleFilled,
  color: '#21A6FF',
  backgroundColor: '#cae9fd',
};

const statusConfig: { [k: string]: StatusConfiguration } = {
  READY: {
    icon: CheckCircleFilled,
    color: '#16B380',
    backgroundColor: '#EDFCF5',
  },
  DID_NOT_START: {
    icon: ExclamationCircleFilled,
    color: '#F77272',
    backgroundColor: '#FEF2F2',
  },
  NOT_AVAILABLE: {
    icon: ExclamationCircleFilled,
    color: '#F77272',
    backgroundColor: '#FEF2F2',
  },
  SKIPPED: {
    icon: ExclamationCircleFilled,
    color: '#21A6FF',
    backgroundColor: '#cae9fd',
  },
  FINISHED: {
    icon: SettingFilled,
    color: '#21A6FF',
    backgroundColor: '#cae9fd',
  },
  PROCESSING: {
    icon: SettingFilled,
    color: '#21A6FF',
    backgroundColor: '#cae9fd',
  },
  LIVE: {
    icon: SettingFilled,
    color: '#21A6FF',
    backgroundColor: '#cae9fd',
  },
};

const statusLabel: { [k: string]: string } = {
  READY: 'Ready',
  SKIPPED: 'Skipped',
  DID_NOT_START: 'Not started',
  FINISHED: 'Processing',
  LIVE: 'Live',
};

const subStatusLabel: { [k: string]: string } = {
  TRANSCRIBED: 'Transcription Ready',
  LIMITED: 'Limited Dialogue',
  CLOUD_RECORDING_DISABLED: 'Recording disabled',
  WAITING_FILES: 'Processing',
  FILES_READY: 'Processing',
  AI_GENERATED: 'Processing',
  TRANSCRIPTION_ERROR: 'Processing Error',
  NOT_RECORDED: 'Not Recorded',
  PARTICIPANT_AUDIO_FILES_DISABLED: 'Audio Files N.A',
  NO_PARTICIPANT_FILES: 'Audio Files N.A.',
  PROCESSING_ERROR: 'Processing Error',
  NOT_CAPTURABLE: 'Not Capturable',
};

const tooltips: { [k: string]: { message: string; link?: string } } = {
  TRANSCRIBED: {
    message: 'The meeting transcription is available. The Smart Summary™️ is not yet available.',
  },
  WAITING_FILES: {
    message: 'The meeting recap is being processed.',
  },
  FILES_READY: {
    message: 'The meeting recap is being processed.',
  },
  AI_GENERATED: {
    message: 'The meeting recap is being processed.',
  },

  LIMITED: {
    message: 'We detected less than 500 characters of dialogue. The recording may have been turned off or the bot removed.',
  },
  CLOUD_RECORDING_DISABLED: {
    message: "Unable to generate a meeting recap because host's Zoom cloud recording was disabled.",
  },
  NOT_AVAILABLE: {
    message: 'We were unable to retrieve the cloud recording from Zoom.',
    link: 'https://help.update.ai/en/articles/6716426-what-does-the-meeting-recap-status-mean#:~:text=no%20meeting%20recap.-,NOT%20AVAILABLE,-(FOR%20ZOOM%20CLOUD)',
  },
  NOT_RECORDED: {
    message: 'Either the Zoom native cloud recording was not started or, if you sent the bot, it was not permitted to enter the meeting or entered but was not granted recording permissions.',
    link: 'https://help.update.ai/en/articles/8470798-understanding-updateai-s-meeting-assistant-a-comprehensive-guide-to-our-bot-s-features#h_6bb46f0450',
  },
  PROCESSING_ERROR: {
    message: 'Contact support and we will do our best to retrieve this recap for you',
    link:'https://www.notion.so/updateai/Marketing-site-requirements-for-www-update-ai-meeting-recaps-175551668db6806a81aaee9e0a7cc99a',
  },
  NOT_CAPTURABLE: {
    message: 'This status is rare and a bit of a black box, generally having to do with your video conferencing recording settings at the time of the meeting.',
    link: 'https://www.notion.so/5fdb618426ea4a498d5dfc1c02027775',
  },
};

const getTooltip = (status: string, subStatus: string) => {  
  const tooltipInfo = tooltips[subStatus];
  if (!tooltipInfo) {
    if (status === EventStatus.DID_NOT_START) {
      return {
        message: 'If you were not expecting this status the most likely reason is that the meeting started over 10 minutes beyond the originally scheduled start time.',
        link: 'https://help.update.ai/en/articles/6716426-what-does-the-meeting-recap-status-mean#:~:text=no%20meeting%20recap.-,NOT%20STARTED,-This%20means%20the',
      };
    }
    return undefined;
  }
  return tooltipInfo;
};

const getLabel = (status: string, subStatus: string) => {
  const labelStatus = statusLabel[status];
  const labelSubStatus = subStatusLabel[subStatus];
  return labelStatus || labelSubStatus || 'Unknown';
};

export const getStatusConfig = (
  status: string,
  subStatus: string,
): StatusConfiguration & { tooltip: { message: string; link?: string } | undefined } => {
  const config = statusConfig[status];
  const tooltip = getTooltip(status, subStatus);

  if (config) {
    return { ...config, tooltip };
  }

  return {
    ...defaultStatus,
    tooltip,
  };
};

interface Props {
  status: string;
  subStatus: string;
}

const RecapStatus: React.FC<Props> = ({ status, subStatus }) => {
  const configuration = getStatusConfig(status, subStatus);

  const tooltipTitle = configuration.tooltip ? (
    <Space direction="vertical">
      <span>{configuration.tooltip.message}</span>
      {configuration.tooltip.link && (
        <a
          onClick={ev => {
            ev.preventDefault();
            ev.stopPropagation();
            window.open(configuration.tooltip?.link, '_blank');
          }}
        >
          Learn more
        </a>
      )}
    </Space>
  ) : undefined;

  return (
    <Tooltip title={tooltipTitle}>
      <Container>
        <span style={{ color: configuration.color }}>
        {configuration.tooltip &&<QuestionCircleOutlined style={{ marginRight: '4px' }} />}
          {getLabel(status, subStatus)}
          </span>
      </Container>
    </Tooltip>
  );
};

export default RecapStatus;