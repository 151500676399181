import { Divider, Select } from 'antd';
import React, { useState } from 'react';
import ToggleCard from '../../../Recordings/ToggleCard';
import Section from '../../../_Misc/Section';
import { LearnMore } from '../../../_Misc/Section/style';
import {
  Description,
  GroupOption,
  MultipleOptions,
  OptionDetail,
  OptionDetailText,
} from '../../style';
import { useToast } from '../../../../../../hooks/useToast';
import { useOrganizationDetails } from '../../../../../../features/organizations/hooks/useOrganizationDetails';
import { extractError } from '../../../../../../utils/api';
import { useOrganizationMembers } from '../../../../../../features/organizations/hooks/useOrganizationMembers';
import { EventVisibility } from '../../../../../../features/recapv2/types';

const options = [
  { value: EventVisibility.Invitees, label: 'Invitees Only' },
  { value: EventVisibility.Organization, label: 'Workspace' },
];

const getOptionLabel = (value: string): string => {
  return options.find(v => v.value === value)?.label || '';
};

export const MeetingVisibilitySection: React.FC = () => {
  const [isModifyingManageGlobally, setIsModifyingManageGlobally] = useState(false);
  const [isModifyingMeetingsVisibility, setIsModifyingMeetingsVisibility] = useState(false);
  const [localVisibility, setLocalVisibility] = useState(false);
  const { success, error } = useToast();
  const {
    changeGlobalMeetingsVisibility,
    disableManageMeetingsVisibilityGlobally,
    enableManageMeetingsVisibilityGlobally,
    globalMeetingsVisibility,
    manageMeetingsVisibilityGlobally,
    permissions,
  } = useOrganizationDetails();
  const { fetchMembers } = useOrganizationMembers();

  const updateInternalMeetingsValue = async (value: string) => {
    setIsModifyingMeetingsVisibility(true);
    try {
      await changeGlobalMeetingsVisibility({
        externalMeetingsVisibility: globalMeetingsVisibility.externalMeetingsVisibility,
        internalMeetingsVisibility: value,
      });
      success('Global visibility modified successfully.');
      fetchMembers();
    } catch (e) {
      const errorMessage = extractError(e);
      error(errorMessage);
    } finally {
      setIsModifyingMeetingsVisibility(false);
    }
  };

  const updateExternalMeetingsValue = async (value: string) => {
    setIsModifyingMeetingsVisibility(true);
    try {
      await changeGlobalMeetingsVisibility({
        externalMeetingsVisibility: value,
        internalMeetingsVisibility: globalMeetingsVisibility.internalMeetingsVisibility,
      });
      success('Global visibility modified successfully.');
      fetchMembers();
    } catch (e) {
      const errorMessage = extractError(e);
      error(errorMessage);
    } finally {
      setIsModifyingMeetingsVisibility(false);
    }
  };

  const handleManageGloballyChanged = async (manage: boolean) => {
    setIsModifyingManageGlobally(true);
    setLocalVisibility(manage);
    try {
      if (manage) await enableManageMeetingsVisibilityGlobally();
      else await disableManageMeetingsVisibilityGlobally();
      fetchMembers();
    } catch (e) {
      setLocalVisibility(!manage);
      const errorMessage = extractError(e);
      error(errorMessage);
    } finally {
      setIsModifyingManageGlobally(false);
    }
  };

  const disableInputs =
    !permissions.manageMeetingsVisibilityGlobally ||
    isModifyingMeetingsVisibility ||
    isModifyingManageGlobally ||
    !manageMeetingsVisibilityGlobally;

    React.useEffect(() => {
      setLocalVisibility(manageMeetingsVisibilityGlobally);
    }, [manageMeetingsVisibilityGlobally]);

  return (
    <>
      <Section
        title="Meetings visibility"
        description={
          'Set the default Global visibility for internal and external meetings for all your users'
        }
      >
        <ToggleCard
          title="Global visibility of meetings within your workspace"
          checked={localVisibility}
          onChange={handleManageGloballyChanged}
          disabled={!permissions.manageMeetingsVisibilityGlobally || isModifyingManageGlobally}
          description={
            'Automatically set the default global visibility settings for internal and external meetings for all your users within your workspace.'
          }
          recommendedSetting="INTERNAL = INVITEES ONLY | EXTERNAL = WORKSPACE"
        >
          <MultipleOptions>
            <GroupOption>
              <OptionDetail>
                <OptionDetailText>Internal meetings</OptionDetailText>
              </OptionDetail>

              <Description>
                Meetings that do not match a calendar event will be treated as internal meetings.
                <LearnMore
                  href={
                    'https://help.update.ai/en/articles/8024488-how-does-updateai-know-whether-a-meeting-is-internal-or-external'
                  }
                  target="_blank"
                >
                  Learn More
                </LearnMore>
              </Description>

              <Select
                disabled={disableInputs}
                value={getOptionLabel(globalMeetingsVisibility.internalMeetingsVisibility)}
                style={{ width: '150px' }}
                onChange={v => updateInternalMeetingsValue(v)}
                options={options}
              />
            </GroupOption>

            <GroupOption>
              <OptionDetail>
                <OptionDetailText>External meetings</OptionDetailText>
              </OptionDetail>

              <div style={{ height: '12px' }} />

              <Select
                disabled={disableInputs}
                value={getOptionLabel(globalMeetingsVisibility.externalMeetingsVisibility)}
                style={{ width: '150px' }}
                onChange={v => updateExternalMeetingsValue(v)}
                options={options}
              />
            </GroupOption>
          </MultipleOptions>
        </ToggleCard>
      </Section>

      <Divider />
    </>
  );
};
