import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import Styled from './style';
import { Connect } from '../../../../../../../features/shared/components/Icons/Connect';
import { Disconnect } from '../../../../../../../features/shared/components/Icons/Disconnect';
import { useToast } from '../../../../../../../hooks/useToast';
import { OptionalTooltipWrapper } from '../../../../../../../componentsV2/OptionalTooltipWrapper';
import { ConnectCRMModal } from './ConnectCRMModal';
import { SubscribeToTeamsPlanLink } from '../../../../../../../componentsV2/UpgradeToPro';
import useFeatures from '../../../../../../../hooks/useFeatures';
import { useCrmIntegration } from '../../../../../../../features/crm-integration/hooks/use-crm-integration';
import { coreService } from '../../../../../../../services/core/core-service';
import {
  SelectedProperty,
  WorkspaceCrmProperty,
} from '../../../../../../../features/crm-integration/crm-integration.types';
import { Button, Flex, Select } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

const { Title, CRMConnectedStatus, SyncNotice } = Styled;

type ConnectionButtonProps = {
  disabled: boolean;
  label: string;
  icon: ReactElement;
  onClick: () => unknown;
  loading: boolean;
};

const ConnectionButton: React.FC<ConnectionButtonProps> = ({
  disabled,
  label,
  icon,
  onClick,
  loading,
}) => {
  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      loading={loading}
      style={{ display: 'flex', padding: '4px 12px 4px 4px' }}
    >
      {icon} {label}{' '}
    </Button>
  );
};

type ConnectButtonProps = Omit<ConnectionButtonProps, 'label' | 'icon'>;
type DisconnectButtonProps = Omit<ConnectButtonProps, 'label' | 'icon'>;

const ConnectButton: React.FC<ConnectButtonProps> = props => {
  return <ConnectionButton {...props} label="Connect CRM" icon={<Connect />} />;
};

const DisconnectButton: React.FC<DisconnectButtonProps> = props => {
  return <ConnectionButton {...props} label="Disconnect CRM" icon={<Disconnect />} />;
};

export interface Props {
  disableUserInteractions: boolean;
}

export const CRMOrganizationSection: React.FC<Props> = ({ disableUserInteractions }) => {
  const {
    disconnect,
    connect,
    isConnectedEnabled,
    isDisconnectEnabled,
    workspaceCrmIntegration,
    isFetching,
  } = useCrmIntegration();
  const [shouldShowConnectModal, setShouldShowConnectModal] = useState(false);
  const [selectedProperties, setSelectedProperties] = useState<SelectedProperty[]>([]);
  const [filterProperties, setFilterProperties] = useState<SelectedProperty[]>([]);
  const [availableProperties, setAvailableProperties] = useState<WorkspaceCrmProperty[]>([]);

  const { error, success } = useToast();

  const features = useFeatures();
  const [isSavingProperties, setIsSavingProperties] = useState(false);
  const availableFeature = features.hubspotIntegration || features.salesforceIntegration;

  const hasCrmConnected = Boolean(workspaceCrmIntegration?.workspace.integration);

  const [isLoading, setIsLoading] = useState(false);

  const handleConnectToCRM = async (selectedCRM: string) => {
    setIsLoading(true);
    try {
      const connectProvider = selectedCRM === 'SALESFORCE' ? 'salesforce' : 'hubspot';
      await connect(connectProvider);
    } catch (e) {
      error((e as Error).message);
    } finally {
      setIsLoading(false);
      setShouldShowConnectModal(false);
    }
  };

  const handleDisconnectToCRM = async () => {
    setIsLoading(true);
    try {
      await disconnect();
      setAvailableProperties([]);
      setFilterProperties([]);
    } catch (e) {
      error((e as Error).message);
    } finally {
      setIsLoading(false);
    }
  };

  const isConnectDisabled =
    disableUserInteractions || hasCrmConnected || !isConnectedEnabled || !availableFeature;
  const isDisconnectDisabled =
    disableUserInteractions || !availableFeature || !hasCrmConnected || !isDisconnectEnabled;

  const status = useMemo(() => {
    if (isFetching) {
      return {
        connected: false,
        status: 'Reading integration status',
      };
    }

    if (!hasCrmConnected) {
      return {
        connected: false,
        status: 'Not connected',
      };
    }

    if (workspaceCrmIntegration?.workspace.integration?.status === 'INVALID') {
      return {
        connected: true,
        status: 'IMPORTANT: Reconnect CRM. Credentials expired.',
      };
    }

    return {
      connected: true,
      status: 'Connected',
    };
  }, [hasCrmConnected, workspaceCrmIntegration, isFetching]);

  useEffect(() => {
    if (workspaceCrmIntegration && workspaceCrmIntegration.workspace) {
      setSelectedProperties(workspaceCrmIntegration.workspace.selectedProperties);
      setAvailableProperties(workspaceCrmIntegration.workspace.properties);
      setFilterProperties(workspaceCrmIntegration.workspace.filterProperties);
    }
  }, [workspaceCrmIntegration]);

  const propertiesOptions = useMemo(() => {
    if (!hasCrmConnected) return [];
    return availableProperties.map(p => ({ label: p.label, value: p.id }));
  }, [availableProperties, hasCrmConnected]);

  const handleOptionClicked = (values: string[]) => {
    const validValues = values.filter(v => availableProperties.find(p => p.id === v) !== undefined);
    setSelectedProperties(validValues);
  };

  const handleFilterOptionClicked = (values: string[]) => {
    const validValues = values.filter(v => availableProperties.find(p => p.id === v) !== undefined);
    setFilterProperties(validValues);
  };

  const handleSave = () => {
    setIsSavingProperties(true);
    coreService
      .updateWorkspaceCrmIntegrationSelectedProperties({
        displayProperties: selectedProperties,
        filterProperties,
      })
      .then(() => {
        success('Account Properties saved successfully');
      })
      .finally(() => setIsSavingProperties(false));
  };

  const isPullingProperties = hasCrmConnected && availableProperties.length === 0;

  return (
    <Flex gap={12} vertical style={{ width: '100%', padding: '0px 16px' }}>
      <ConnectCRMModal
        isLoading={isLoading}
        isOpen={shouldShowConnectModal}
        onCancel={() => setShouldShowConnectModal(false)}
        onClose={() => setShouldShowConnectModal(false)}
        onConfirm={handleConnectToCRM}
      />

      <Title>
        CRM Integration Status:{' '}
        <CRMConnectedStatus connected={status.connected}>{status.status}</CRMConnectedStatus>
      </Title>

      {status.connected && (
        <SyncNotice>
          <InfoCircleOutlined /> Note: CRM account data syncs automatically every hour
        </SyncNotice>
      )}

      <OptionalTooltipWrapper
        display={disableUserInteractions || !availableFeature}
        value={
          !availableFeature ? (
            <SubscribeToTeamsPlanLink title="Upgrade to a team plan to push your insights to your CRM." />
          ) : (
            'Only the Owner and Admins of the organization can change this setting.'
          )
        }
      >
        <div>
          {!workspaceCrmIntegration?.workspace.integration ? (
            <ConnectButton
              onClick={() => setShouldShowConnectModal(true)}
              disabled={isConnectDisabled}
              loading={isLoading}
            />
          ) : (
            <DisconnectButton
              onClick={handleDisconnectToCRM}
              disabled={isDisconnectDisabled}
              loading={isLoading}
            />
          )}
        </div>
      </OptionalTooltipWrapper>
      {isPullingProperties ? (
        <Flex gap={4} vertical>
          <Title>
            Account properties list is being pulled from the CRM. Please refresh the page to update
            the status.
          </Title>
          <Title>
            Once this is finished, you'll be able to select which properties to display in Account
            Insights or use as filters in dashboards.
          </Title>
        </Flex>
      ) : !hasCrmConnected ? null : (
        <Flex vertical gap={8}>
          <Flex vertical gap={8}>
            <Title>
              {isPullingProperties && hasCrmConnected
                ? `Account properties list is being pulled from the CRM. Please refresh the page to update the status.`
                : 'Account properties to display'}
            </Title>

            <Select
              mode="tags"
              loading={isPullingProperties}
              disabled={!hasCrmConnected || isPullingProperties}
              onChange={value => handleOptionClicked(value)}
              autoClearSearchValue={true}
              value={selectedProperties}
              options={propertiesOptions}
              style={{ width: '50%' }}
            />
          </Flex>

          <Flex vertical gap={8}>
            <Title>Account properties to use as filter</Title>

            <Select
              mode="tags"
              loading={isPullingProperties}
              disabled={!hasCrmConnected || isPullingProperties}
              onChange={value => handleFilterOptionClicked(value)}
              autoClearSearchValue={true}
              value={filterProperties}
              options={propertiesOptions}
              style={{ width: '50%' }}
            />
          </Flex>

          <Flex>
            <Button
              disabled={!hasCrmConnected || isFetching || isSavingProperties}
              onClick={handleSave}
              type="primary"
            >
              Save Properties
            </Button>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};
