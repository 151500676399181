import { Button, Divider, Flex, Input, Modal } from 'antd';
import React, { useMemo, useState } from 'react';
import Styled from './styles';
import { NoCRMConnectionMessage } from '../../../../../shared/components/CRMConnection';
import { SearchOutlined } from '@ant-design/icons';
import { useSearchAccountOrEmailDomain } from '../../../../../../hooks/useSearchAccountsOrEmailDomain';
import up from '../../../../../../assets/svg/upload-04.svg';
import { Results } from './Results/Results';
import { ProviderIntegration } from '../../../../../../redux/typings/store';
import { ResultItem } from './Results/ResultItem/ResultItem';
import { useNavigate } from 'react-router-dom';
import { Plus } from '../../../../../shared/components/Icons/Plus';

const {
  Container,
  Circle,
  Content,
  SubContent,
  Header,
  ButtonGroup,
  Title,
  MatchingCRMAccounts,
  MatchingCRMAccountsContainer,
  MatchingCRMAccountsTitle,
  RefreshButtonContainer,
  ProspectedAccountsContainer,
  AccountsContainer,
  DetailText,
  OtherCrmAccounts,
  DomainAccounts,
  AssignDomainText,
  CreateDomainContainer,
  CreateDomainText,
} = Styled;

interface Props {
  centered?: boolean;
  destroyOnClose?: boolean;
  title?: boolean;
  closable?: boolean;
  footer?: boolean;
  open: boolean;
  onResultSelect: (acc: ProviderIntegration | null) => unknown;
  selectedAccount: ProviderIntegration | null;
  onCancel: () => unknown;
  onAccountSelection: (acc: ProviderIntegration) => unknown;
  isWorking?: boolean;
  prospectedAccounts: ProviderIntegration[] | null;
}

export const AccountModal: React.FC<Props> = ({
  open,
  centered,
  closable,
  destroyOnClose,
  footer,
  title,
  onResultSelect,
  selectedAccount,
  onCancel,
  onAccountSelection,
  isWorking,
  prospectedAccounts,
}) => {
  const navigate = useNavigate();
  const [selected, setSelected] = useState<ProviderIntegration | null>(selectedAccount);
  const [searchTerm, setSearchTerm] = useState('');
  const { search, isSearching, accounts, setSearch } = useSearchAccountOrEmailDomain();

  const crmAccounts = useMemo(() => {
    const deduplicatedAccounts = accounts.filter(acc => {
      if (prospectedAccounts?.some(prospAcc => prospAcc.id === acc.id)) return false;
      return true;
    });
    return deduplicatedAccounts.filter(acc => acc.provider !== 'email');
  }, [accounts]);

  const emailAccounts = useMemo(() => {
    const filteredAccounts = accounts.filter(acc => acc.provider === 'email');
    return filteredAccounts;
  }, [accounts, selected]);

  const goToIntegrations = () => {
    navigate('/settings?tab=integrations');
  };

  const handleCreateDomainAccount = (account: ProviderIntegration) => {
    onAccountSelection(account);
    setSearch('');
  };

  return (
    <Modal
      centered={centered}
      destroyOnClose={destroyOnClose}
      title={title}
      closable={closable}
      footer={footer}
      open={open}
    >
      <Container>
        <Header>
          <Circle>
            <img src={up} style={{ height: '24px', width: '24px' }} />
          </Circle>
          <Content>
            <Title>Select an account</Title>
            <SubContent>Recaps will be pushed into the account you chooses.</SubContent>
          </Content>
        </Header>
        <Flex gap={8}>
          <Input
            allowClear
            value={searchTerm}
            onChange={ev => setSearchTerm(ev.target.value)}
            prefix={<SearchOutlined />}
            placeholder={'Type an account name'}
            ref={input => {
              if (input) setTimeout(() => input.focus());
            }}
          />
          <Button
            disabled={isSearching || searchTerm.length < 4}
            type="primary"
            onClick={() => setSearch(searchTerm)}
          >
            Search
          </Button>
        </Flex>

        <DetailText>
          Account not showing? Go to the <a onClick={goToIntegrations}>integrations page</a> and
          refresh your integrations.
        </DetailText>

        <AccountsContainer>
          <MatchingCRMAccountsContainer>
            <MatchingCRMAccounts>
              <MatchingCRMAccountsTitle>{`Matching CRM accounts (${
                prospectedAccounts?.length || 0
              })`}</MatchingCRMAccountsTitle>
              <RefreshButtonContainer>
                {/* <RefreshButton>Refresh</RefreshButton> */}
              </RefreshButtonContainer>
            </MatchingCRMAccounts>
          </MatchingCRMAccountsContainer>

          <ProspectedAccountsContainer>
            <Divider style={{ margin: '0px' }} />
            {prospectedAccounts?.map(acc => (
              <ResultItem
                key={acc.id}
                onClick={() => {
                  onResultSelect(acc);
                  setSelected(acc);
                }}
                name={acc.name}
                provider={acc.provider}
                selected={acc.id === selected?.id}
              />
            ))}
          </ProspectedAccountsContainer>

          {search ? (
            <OtherCrmAccounts>
              <Divider style={{ margin: '0px' }} />
              <MatchingCRMAccountsTitle>{`Other CRM accounts (${crmAccounts.length})`}</MatchingCRMAccountsTitle>
              <Results
                loading={isSearching}
                accounts={crmAccounts}
                onSelect={onResultSelect}
                selected={selected}
                setSelected={setSelected}
              />
            </OtherCrmAccounts>
          ) : (
            <NoCRMConnectionMessage />
          )}

          {search &&
            !isSearching &&
            (emailAccounts && emailAccounts.length > 0 ? (
              <DomainAccounts>
                <Divider style={{ margin: '0px' }} />
                <MatchingCRMAccountsTitle>{`Domains (${emailAccounts.length})`}</MatchingCRMAccountsTitle>
                <Results
                  loading={isSearching}
                  accounts={emailAccounts}
                  onSelect={onResultSelect}
                  selected={selected}
                  setSelected={setSelected}
                />
              </DomainAccounts>
            ) : (
              <CreateDomainContainer>
                <CreateDomainText>{`The domain “${search}” doesn’t exist...`}</CreateDomainText>
                <AssignDomainText
                  onClick={() => {
                    const newAccount: ProviderIntegration = {
                      id: search,
                      name: search,
                      provider: 'email',
                    };
                    handleCreateDomainAccount(newAccount);
                  }}
                >
                  <Plus /> Create and assign this domain
                </AssignDomainText>
              </CreateDomainContainer>
            ))}
        </AccountsContainer>

        <ButtonGroup>
          <Button type="default" children="Cancel" onClick={onCancel} style={{ flex: 2 }} />
          <Button
            type="primary"
            children="Apply Changes"
            loading={isWorking}
            onClick={() => {
              if (selected) onAccountSelection(selected);
            }}
            disabled={!!!selectedAccount}
            style={{ flex: 2 }}
          />
        </ButtonGroup>
      </Container>
    </Modal>
  );
};
